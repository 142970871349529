import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import ListGroup from "react-bootstrap/ListGroup";
import { CountBadge, SportIcon, LeagueName, ScoreBadge, SubScoreBadge, GoalIcon, GoalIconContainer } from "./EventsModal.styled";
import { getSportIcon, getSportIconColor, getScoreForArbs, getSubScoreForArbs, getGoalIcon, checkIfScoreChanged } from "../../utils/events";

export const EventsModal = React.memo(function ConfigureArbsModal({
  show,
  setVisible,
  events,
}) {
  const close = () => {
    setVisible(false);
  };

  const [selectedSport, setSport] = useState(undefined);

  return (
    <Modal size="lg" show={show} onHide={close} centered>
      <Modal.Header style={{
      backgroundColor: "#111E12",
      fontFamily: "Montserrat",
    }} className="pb-0 border-bottom-0" closeButton>
        <Modal.Title>Eventos</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
      backgroundColor: "#111E12",
      fontFamily: "Montserrat",
    }}className="pt-0">
        <Nav fill style={{ marginTop: '10px', backgroundColor: "#111E12", fontFamily: "Montserrat" }} variant="tabs" defaultActiveKey={Object.keys(events)[0]}>
          {Object.keys(events).map((sportName) => (
            <Nav.Item style={{ backgroundColor: "#111E12", fontFamily: "Montserrat" }} onClick={() => setSport(sportName)}>
              <Nav.Link eventKey={sportName}>
              <SportIcon className="sport" src={getSportIcon(sportName)} filter={getSportIconColor(sportName)} />
              <a style={{ textTransform: 'capitalize' }}>{sportName} </a>
              <CountBadge className="badgeCustom badge badge-light">{events[sportName].length}</CountBadge>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <ListGroup>
        {(selectedSport ? events[selectedSport] : Object.values(events)[0]) ?.sort((a, b) => b.bookies - a.bookies).map((event) => (
            <ListGroup.Item>{event.name} <LeagueName>| {event.leagueName}</LeagueName>
            <ScoreBadge className="badge badge-secondary">{getScoreForArbs(event)}</ScoreBadge>
            { getSubScoreForArbs(event) && <SubScoreBadge className="badge badge-secondary">{getSubScoreForArbs(event)}</SubScoreBadge>}
            { checkIfScoreChanged(event) && <GoalIconContainer className="badge badge-secondary">
              <GoalIcon src={getGoalIcon(event.sport?.name)}/> 
              <b>GOAL</b>
            </GoalIconContainer> }
            <CountBadge className="badgeCustom badge badge-light">{event.bookies}</CountBadge>
            </ListGroup.Item>
          ))}
          </ListGroup>

      </Modal.Body>
    </Modal>
  );
});
