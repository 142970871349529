import styled from "styled-components";

export const PageContainer = styled.div`
  // display: flex;
  // padding: 20px;
  // background-color: #111E12; // dark background color
  // color: white;
  // flex-direction: column;
  
  display: flex;
  background-color: #111E12; // Adjust based on the design background color
  flex-direction: column;
  
  min-height: 100vh;
  padding: 20px;
`;

export const Subcontainer = styled.div`
  // display: flex;
  // width: 100%;
  // padding: 20px;

  // // justify-content: space-between;
  // // align-content: stretch;
  // // align-items: center;

  display: flex;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 50px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const SubscriptionCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InvoicesCard = styled.div`
  width: 100%;
  max-width: 450px; // Adjust width as needed
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; // Changed from center to flex-start
  justify-content: flex-start; // Changed from center to flex-start
  height: 100%;
  max-height: 500px;
  overflow-y: auto; // Changed from scroll to auto
  gap: 5px;
`;

export const AccessButton = styled.a`
  background-color: #D9D9D9; // Green button
  border: none;
  padding: 10px 15px;
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 10px;

  background: #E9FD8C;
  color: #111E12;
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;

  :hover {
    color: #1BB78A;
  }
`;

export const InvoicesTitle = styled.h1`
  font-size: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  color: #111E12;
  letter-spacing: -0.05em;
  text-align: center;
`;

export const FreezeButton = styled.button`
  background-color: #350F87; // Green button
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  width: 80%;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #1BB78A;
  }
`;

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: ${props => props.filter};
`
export const ModalButton = styled.button`
  background-color: white; // Green button
  color: #111E12;
  border: none;
  padding: 15px;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 10px;

  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    color: #1BB78A;
  }
`;

export const SubscriptionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
  gap: 1em;
`;

export const PurchaseCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
  gap: 1em;
  background-color: #111E12;
  color: white;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1em;
`;

export const InvoiceCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1em;
  width: 300px;
`;

export const InvoiceTitle = styled.h1`
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  color: #111E12;
  letter-spacing: -0.05em;
  text-align: center;
`;

export const InvoiceAmount = styled.h1`
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-bottom: 0;
  color: #111E12;
  letter-spacing: -0.05em;
  text-align: center;
`;

export const DownloadButton = styled.button`
  background-color: #111E12;
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;

  :hover {
    background: #1BB78A;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  width: 100%;
  max-width: 450px; // Adjust width as needed
`;