// FooterSection.js
import React from 'react';
import logo from "../../../static/logo_white.svg";
import { Trans, useTranslation } from 'react-i18next';
import {
  Section,
  SubsectionContainer,
  WebsiteLinks,
  Footer,
  Logo,
  CompanyInformation,
  CompanyData,
  Link,
  FooterLinks
} from './FooterSection.style.js';

// Component
export const FooterSection = () => {
  const { t } = useTranslation();

    return (
        <Section>
            <SubsectionContainer>
                <Footer>
                    <Logo src={logo} alt="BetSmarter Logo" />
                    <CompanyInformation>
                        <CompanyData>BetSmarter OÜ</CompanyData>
                        <CompanyData>16210840 © 2019-{new Date().getFullYear()}</CompanyData>
                        <CompanyData>contact@betsmarter.app</CompanyData>
                    </CompanyInformation>
                </Footer>
                <FooterLinks>
                    <WebsiteLinks>
                        <Link href="/pricing">{t('pricing')}</Link>
                        <Link href="/register">{t('register')}</Link>
                        <Link href="/login">{t('login')}</Link>
                        <Link href="/arbs/bookies">Bookies</Link>
                        {/* <Link href="/faq">{t('faq')}</Link>
                        <Link href="/contact">{t('contact')}</Link> */}
                    </WebsiteLinks>
                </FooterLinks>
            </SubsectionContainer>
        </Section>
    );
};