import styled from "styled-components";

export const CountBadge = styled.span`
  margin-left: 10px;
`
export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: ${props => props.filter};
`

export const LeagueName = styled.a`
  color: #758b77;
`

export const ScoreBadge = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: rgba(85, 85, 187, 0.2);
  border: 1px solid #5556bb;
`

export const SubScoreBadge = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: rgba(21, 21, 97, 0.2);
  border: 1px solid #646595;
`

export const GoalIconContainer = styled.div`
  margin-left: 5px;
  font-size: medium;
  background-color: red;
  width: auto;

  > b {
    margin-left: 5px;
    color: white;

    @keyframes slowlyAppear {
      0% {
        opacity: 0;
      }
      35% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  
   animation: slowlyAppear 2s infinite;  
    }
  
`

export const GoalIcon = styled.img`
  height: 13px;
  
  @keyframes slowlyAppear {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

 animation: slowlyAppear 2s infinite;  
`