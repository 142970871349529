import styled from 'styled-components';

export const Section = styled.section`
  color: white;
  text-align: center;
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
`;

export const SubsectionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-end;
  padding: 20px;
  flex-direction: row;
  max-width: 1600px;
  
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  flex-direction: column;

  @media (max-width: 800px) {
    padding-top: 0px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  flex-direction: column;

  @media (max-width: 800px) {
    padding-top: 0px;
  }

  @media (max-width: 400px) {
    order: -1;
  }
`;

export const Logo = styled.img`
  max-width: 50px; // Set the max width for the logo
  margin-bottom: 20px; // Space between logo and social links
`;

export const CompanyInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  `;

export const CompanyData = styled.p`
  margin: 0px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
`

export const WebsiteLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0px;

  @media (max-width: 400px) {
    align-items: flex-start;
  }
`;

export const Link = styled.a`
  color: white;
  font-weight: bold;


  &:hover {
    text-decoration: none;
    color: #e9fd8c;
  }
`;