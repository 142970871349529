import React from "react";
import "./Home.styled";
import { useTranslation } from 'react-i18next';

import { FeaturesSection } from "./FeaturesSection/FeaturesSection";
import { FooterSection } from "./FooterSection/FooterSection";
import { NavBarMain } from "../NavBarMain/NavBarMain";
import { DotLottiePlayer } from '@dotlottie/react-player';
import explainerVideo from "../../static/home/explainer.lottie";

import {
  Container,
  SubContainer,
  Header,
  Main,
  Hero,
  HeroHeadingContainer,
  HeroHeading,
  HeroSubHeading,
  Button,
  HeroVideo,
  Background,
} from './Home.styled.js';

export const Home = ({ history }) => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Background/>
        <SubContainer>
          <Header>
            <NavBarMain currentPage="globalHome" history={history} />
          </Header>
          <Main>
            <Hero>
              <HeroHeadingContainer>
                <HeroHeading>{t('earn')}.</HeroHeading>
                <HeroHeading style={{ color: '#E9FD8C', fontStyle: 'italic' }}>{t('money')}.</HeroHeading>
                <HeroHeading>{t('safely')}.</HeroHeading>
              </HeroHeadingContainer>
              <HeroSubHeading>{t('subHeading')}</HeroSubHeading>
              <Button href="/register">{t('heroButton')}</Button>
            </Hero>
            <HeroVideo>
            <DotLottiePlayer
                  src={explainerVideo}
                  autoplay
                  loop={false}
                  speed={0.9}
                  style={{ width: '100%' }}
                >
                </DotLottiePlayer>
            </HeroVideo>
          </Main>
        </SubContainer>
      </Container>
      <FeaturesSection />
      <FooterSection />
    </>
  );
};