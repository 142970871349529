import React, { useMemo, useState, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import { DotLottiePlayer, Controls } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
import { observer } from "mobx-react-lite";

import {
  Button,
  SectionContainer,
  CardTextContainer,
  RowFirst,
  TopContainerRowFirst,
  CardLatency,
  CardBodyLatency,
  CardBrowser,
  CardBodyBrowser,
  ButtonBrowser,
  CardDuration,
  CardBodyDuration,
  CardTextContainerDuration,
  Column,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardImage,
  CardImageContainer,
  CardFooter,
  ComparisonContainer,
  BarContainer,
  Bar,
  CardAnimationsContainer,
  AnimatedBookieBadge,
  BookieBadge,
  RowSecond,
  CardSports,
  CardBodySports,
  CardTextSports,
  DotLottiePlayerSports,
  CardCalendarCustom,
  RowThird,
  CardTextContainerConfiguration,
  CardHeaderConfiguration,
  CardTextConfiguration,
  CardImageConfiguration,
  CardCalendar,
  CardBodyCalendar,
  RowFourth,
  YieldTopContainer,
  CardYield,
  ComparisonContainerYield,
  CardReach,
  CardBodyReach,
  TopContainerReach,
  CardTextContainerReach,
  CardHeaderReach,
  CardTextReach,
  RightTopContainerReach,
  DotLottiePlayerReach,
  BarContainerVertical,
  VerticalBar,
  CheckBookiesButton,
  CheckBookiesButtonMobile,
  ArbContainer,
  EventContainer,
  SportIcon, 
  EventTextContainer,
  EventName,
  EventLeague,
  BetCardTable,
  ArbColumn,
  RowContentContainer,
  ProfitBadgeText,
  ElapsedTimeText,
  BookieName,
  BetCardRow,
  BetName,
  BetOdds
} from './FeaturesSection.style.js';

const bookies = ['bet365', 'betfair', 'winamax', 'codere', 'casumo'];

const getBookies = () => {
  let bookie1 = bookies[Math.floor(Math.random() * bookies.length)];
  let bookie2;
  do {
    bookie2 = bookies[Math.floor(Math.random() * bookies.length)];
  } while (bookie1 === bookie2);
  return [bookie1, bookie2];
};

const getArb = ({ sport, name, league, markets }) => {
  const bookies = getBookies();

  return {
    event: {
      sport,
      name,
      league
    },
    timestamp: new Date().getTime(),
    sortedBets: markets.map((market, idx) => ({
      id: `${idx}`,
      bookie: bookies[idx],
      odds: market.odds,
      overTimeIncluded: true,
      name: market.name
    }))
  }
}

const arbs = [
  getArb({
    sport: 'tennis',
    name: 'Alcaraz vs Djokovic',
    league: 'Wimbledon',
    markets: [
      { name: 'W1', odds: 2.2 },
      { name: 'W2', odds: 2 }
    ]
  }),
  getArb({
    sport: 'football',
    name: 'Real Madrid vs Barcelona',
    league: 'La Liga',
    markets: [
      { name: 'FT-1', odds: 1.3 },
      { name: 'DC-X2', odds: 5.4 }
    ]
  })
];

import { SettingsProvider } from "../../../contexts";
import { useSettings } from "../../../hooks";
import { getSportIcon, getSportIconColor } from '../../../utils/events'

import configurationImage from "../../../static/home/c.png";
import handImage from "../../../static/home/hand.lottie";
import chromeImage from "../../../static/home/chrome.lottie";
import bluelightImage from "../../../static/home/bluelight.lottie";
import logoImage from "../../../static/logoWithName.png";
import basketballImage from "../../../static/home/basketball.lottie";
import footballImage from "../../../static/home/football.lottie";
import tennisImage from "../../../static/home/tennis.lottie";
import americanFootballImage from "../../../static/home/americanFootball.lottie";
import calendarImage from "../../../static/home/calendar.lottie";
import yieldImage from "../../../static/home/yield.lottie";
import earthImage from "../../../static/home/earth.lottie";
import LogoWhite from "../../../static/logo_white.svg";

export const FeaturesSection = (props) => (
  <SettingsProvider type="live" >
      <FeaturesSectionContainer {...props} />
  </SettingsProvider>
);

// Return a string for the duration between two dates, i.e 3d 18h 36m 45s
const getDuration = (start, end) => {
  const duration = Math.min(end - start, 20000);
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((duration % (1000 * 60)) / 1000);

  // return only when bigger than 0
  if (days > 0) return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`;
  if (minutes > 0) return `${minutes}m ${seconds}s`;
  if (seconds > 0) return `${seconds}s`;
}

const getArrayDivision = (array, division) => {
  const divisionSize = Math.floor(array.length / 3);
  const start = (division - 1) * divisionSize;
  const end = division * divisionSize;
  return array.slice(start, end);
}

const getBookieBadgeComponent = (bookie) => (
  Math.random() > 0.35 ? (
    <BookieBadge key={bookie} forwardedAs="label">
      {bookie.label || bookie.bookie}
    </BookieBadge>
  ) : (
    <AnimatedBookieBadge key={bookie} forwardedAs="label">
      {bookie.label || bookie.bookie}
    </AnimatedBookieBadge>
  )
);

const BookieMarquee = observer(({ bookies }) => {
  const bookieBadges = useMemo(() => {
    return new Array(3).fill(0).map((_, i) => (
      <div key={i} className="marquee">
        <div className="marquee__inner" aria-hidden="true">
          {
            [...Array(5)].map(() => getArrayDivision(bookies, i + 1)).flat()
              .map(bookie => (
                getBookieBadgeComponent(bookie)
              ))
          }
        </div>
      </div>
    ));
  }, [bookies]);

  return (
    <>
      {bookieBadges}
    </>
  );
});

const FeaturesSectionContainer = observer(() => {
  const { t } = useTranslation();
  const { options } = useSettings();
  const [currentTime, setTime] = useState(new Date)

  useEffect(() => {
    const interval = window.setInterval(() => {
      setTime(new Date())
    }, 1000)

      // Clear interval
      return () => window.clearInterval(interval);
    }, []);

  const bookies = useMemo(() => 
    (options?.bookies || [])
      .flatMap(bookie => [bookie, ...(bookie.clones || [])]).filter(bookie => !bookie.removed), 
      [options.bookies]);

  const arb = useMemo(() => arbs[Math.floor(Math.random() * arbs.length)], []);

  return (
    <>
      <SectionContainer>
        <RowFirst>
          <TopContainerRowFirst>
            <CardLatency>
              <CardBodyLatency>
                <DotLottiePlayer
                  src={bluelightImage}
                  autoplay
                  loop={true}
                  style={{ height: 100, width: 100 }}
                >
                </DotLottiePlayer>
                <CardTextContainer>
                  <CardHeader>
                  {t('latencyHeader')}
                  </CardHeader>
                  <CardText>
                  <Trans >
                    {t('latencyText')}
                  </Trans>
                  </CardText>
                </CardTextContainer>
              </CardBodyLatency>
              <CardFooter>
                <ComparisonContainer>
                  <BarContainer>
                    <ScrollAnimation animateIn='growWidth100' offset={0}><Bar width="100px" style={{ height: '30px', background: '#B8DCEF', fontWeight: 'bold' }}>~100ms</Bar></ScrollAnimation>
                    <img src={logoImage} alt="BetSmarter" style={{ height: '30px', width: '120px' }}></img>
                  </BarContainer>
                  <BarContainer>
                    <ScrollAnimation animateIn='growWidth300' offset={0}><Bar width="280px" style={{ height: '30px', background: '#49575E', color: 'white' }}>{'>'}1000ms</Bar></ScrollAnimation>
                    {t('competitors')}
                  </BarContainer>
                </ComparisonContainer>
              </CardFooter>
            </CardLatency>
            <CardBrowser>
              <CardBodyBrowser>
                <DotLottiePlayer
                  src={chromeImage}
                  autoplay
                  style={{ height: 100, width: 100 }}
                >
                </DotLottiePlayer>
                <CardTextContainer>
                  <CardHeader>
                  {t('browserHeader')}
                  </CardHeader>
                  <CardText>
                  <Trans >
                    {t('browserText')}
                  </Trans>
                  </CardText>
                </CardTextContainer>
              </CardBodyBrowser>
              <CardFooter>
                <ScrollAnimation animateIn='fadeIn' offset={0}><ButtonBrowser href="/register">{t('downloadButton')}</ButtonBrowser></ScrollAnimation>
              </CardFooter>
            </CardBrowser>
          </TopContainerRowFirst>
          <CardDuration>
            <CardBodyDuration>
              <DotLottiePlayer
                src={handImage}
                autoplay
                style={{ height: 100, width: 100 }}
                loop={true}
              >
              </DotLottiePlayer>
              <CardTextContainerDuration>
                <CardHeader>
                  {t('durationHeader')}
                </CardHeader>
                <CardText>
                  <Trans >
                    {t('durationText')}
                  </Trans>
                </CardText>
              </CardTextContainerDuration>
            </CardBodyDuration>
            <CardFooter>
              {/* <ScrollAnimation animateIn='slideInUp' offset={0}>  <CardImage src={arbImage} alt="Arb" style={{ height: '156px', width: '409px', marginBottom: '-30px' }} /></ScrollAnimation> */}
              <ScrollAnimation animateIn='slideInUp' offset={0}>
              <ArbContainer>
                <EventContainer>
                  <SportIcon className="sport" data-sport-name={arb.event.sport} src={getSportIcon(arb.event.sport)} filter={getSportIconColor(arb.event.sport)} />
                  <EventTextContainer>
                    <EventName>{arb.event.name}</EventName>
                    <EventLeague>{arb.event.league}</EventLeague>
                  </EventTextContainer>
                </EventContainer>
                <BetCardTable>
                  <ArbColumn small={true} className="firstRow" style={{ widht: '50px' }}>
                    <RowContentContainer>
                      <ProfitBadgeText className="badge badge-secondary">
                        {((1 - (1 / arb.sortedBets[0].odds + (1 / arb.sortedBets[1].odds))) * 100).toFixed(2)}%
                      </ProfitBadgeText>
                      <ElapsedTimeText>
                       {getDuration(new Date(arb.timestamp), currentTime)}
                      </ElapsedTimeText>
                    </RowContentContainer>
                  </ArbColumn>
                  <ArbColumn small={true}>
                    <RowContentContainer>
                      {arb.sortedBets.map((bet, idx) =>
                        <BetCardRow key={bet.id} className="bookie" idx={idx}>
                          <p>
                            <BookieName>{bet.bookie}</BookieName>
                          </p>
                        </BetCardRow>)
                      }
                    </RowContentContainer>
                  </ArbColumn>
                  <ArbColumn className="column">
                    <RowContentContainer>
                      {arb.sortedBets.map((bet, idx) => (
                        <BetCardRow key={bet.id} small={true} idx={idx}>
                          <BetName className="market">{bet.name}</BetName>
                        </BetCardRow>
                      ))}
                    </RowContentContainer>
                  </ArbColumn>
                  <ArbColumn small={true}>
                    <RowContentContainer>
                      {arb.sortedBets.map((bet, idx) => (
                        <BetCardRow key={bet.id} className="odds" small={true} idx={idx}>
                          <BetOdds>{Math.round(bet.odds * 100) / 100}</BetOdds>
                        </BetCardRow>
                      ))}
                    </RowContentContainer>
                  </ArbColumn>
                </BetCardTable>
              </ArbContainer>
              </ScrollAnimation>
            </CardFooter>
          </CardDuration>
        </RowFirst>
        <RowSecond>
          <CardSports>
            <CardBodySports>
              <CardAnimationsContainer>
                <DotLottiePlayerSports
                  src={basketballImage}
                  autoplay
                  loop={true}
                >
                </DotLottiePlayerSports>
                <DotLottiePlayerSports
                  src={footballImage}
                  autoplay
                  loop={true}
                >
                </DotLottiePlayerSports>
              </CardAnimationsContainer>
              <CardTextContainer style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <CardHeader style={{ fontSize: '50px', color: '#1F3621' }}>{t('sportsHeader')}</CardHeader>
                <CardTextSports>
                  <Trans >
                    {t('sportsText')}
                  </Trans>
                </CardTextSports>
              </CardTextContainer>
              <CardAnimationsContainer>
                <DotLottiePlayerSports
                  src={tennisImage}
                  autoplay
                  loop={true}
                >
                </DotLottiePlayerSports>
                <DotLottiePlayerSports
                  src={americanFootballImage}
                  autoplay
                  loop={true}
                >
                </DotLottiePlayerSports>
              </CardAnimationsContainer>
            </CardBodySports>
          </CardSports>
          <CardCalendarCustom>
            <CardBodyCalendar>
              <DotLottiePlayer
                src={calendarImage}
                autoplay
                loop={true}
                style={{ height: 180, width: 180, marginLeft: '-50px', marginTop: '-40px' }}
              >
              </DotLottiePlayer>
              <CardTextContainer>
                <CardHeader style={{ fontSize: '40px' }}>
                  {t('calendarHeader')}
                </CardHeader>
                <CardText style={{ fontSize: '30px', color: 'rgba(54, 58, 61, 0.75)' }}>
                <Trans >
                    {t('calendarText')}
                  </Trans>
                </CardText>
              </CardTextContainer>
              <Button href="/register">{t('calendarButton')}</Button>
            </CardBodyCalendar>
          </CardCalendarCustom>
        </RowSecond>
        <RowThird>
          <Card background="radial-gradient(50% 50% at 50.5% 73.2%,#537853 0%,rgb(29,45,29) 100%)" maxWidth="1600px" height="550px" style={{ alignItems: 'center' }}>
            <CardBody style={{ flexDirection: 'column' }}>
              <CardTextContainerConfiguration>
                <CardHeaderConfiguration>{t('configurationHeader')}</CardHeaderConfiguration>
                <CardTextConfiguration>
                  <Trans >
                    {t('configurationText')}
                  </Trans>
                </CardTextConfiguration>
              </CardTextContainerConfiguration>
              <ScrollAnimation animateIn='slideInUp' offset={0}><CardImageConfiguration src={configurationImage} alt="Configuration" /></ScrollAnimation>
            </CardBody>
          </Card>
          <CardCalendar>
            <CardBodyCalendar>
              <DotLottiePlayer
                src={calendarImage}
                autoplay
                loop={true}
                style={{ height: 180, width: 180, marginLeft: '-50px', marginTop: '-40px' }}
              >
              </DotLottiePlayer>
              <CardTextContainer>
                <CardHeader style={{ fontSize: '40px' }}>
                {t('calendarHeader')}
                </CardHeader>
                <CardText style={{ fontSize: '30px', color: 'rgba(54, 58, 61, 0.75)' }}>
                  <Trans >
                    {t('calendarText')}
                  </Trans>
                </CardText>
              </CardTextContainer>
                <Button href="/register">{t('calendarButton')}</Button>
            </CardBodyCalendar>
          </CardCalendar>
        </RowThird>
        <RowFourth>
          <CardYield>
            <YieldTopContainer>
              <DotLottiePlayer
                src={yieldImage}
                autoplay
                loop={true}
                style={{ height: 120, width: 120, marginLeft: '-20px', marginTop: '-20px' }}
              >
              </DotLottiePlayer>
              <CardTextContainer>
                <CardHeader style={{ fontSize: '40px', color: '#1D2D1D' }}>
                  {t('yieldHeader')}
                </CardHeader>
                <CardText style={{ fontSize: '30px', color: 'rgba(39, 59, 39, 0.8)' }}>
                  <Trans >
                    {t('yieldText')}
                  </Trans>
                </CardText>
              </CardTextContainer>
            </YieldTopContainer>
            <CardFooter style={{ alignSelf: 'center' }}>
              <ComparisonContainerYield>
                <BarContainerVertical>
                  <img src={LogoWhite} alt="BetSmarter" style={{ height: '30px', width: '30px' }}></img>
                  <ScrollAnimation animateIn='growHeight200' offset={0}><VerticalBar style={{  height: '200px', width: '40px', background: '#B8DCEF', fontWeight: 'bold' }}>8%</VerticalBar></ScrollAnimation>
                </BarContainerVertical>
                <BarContainerVertical>
                  {t('competitors')}
                  <ScrollAnimation animateIn='growHeight75' offset={0}><VerticalBar style={{ height: '100px', width: '40px', background: '#49575E', color: 'white' }}>3%</VerticalBar></ScrollAnimation>
                </BarContainerVertical>
              </ComparisonContainerYield>
            </CardFooter>
          </CardYield>
          <CardReach>
            <CardBodyReach>
              <TopContainerReach>
                <CardTextContainerReach>
                  <CardHeaderReach>{t('reachHeader')}</CardHeaderReach>
                  <CardTextReach>
                  <Trans >
                    {t('reachText')}
                  </Trans>
                  </CardTextReach>
                  <CheckBookiesButtonMobile href="/arbs/bookies">{t('checkBookiesButton')}</CheckBookiesButtonMobile>
                </CardTextContainerReach>
                <RightTopContainerReach>
                  <DotLottiePlayerReach
                    src={earthImage}
                    autoplay
                    loop={true}
                  >
                  </DotLottiePlayerReach>
                  <CheckBookiesButton href="/arbs/bookies">{t('checkBookiesButton')}</CheckBookiesButton>
                </RightTopContainerReach>
              </TopContainerReach>
            </CardBodyReach>
            <ScrollAnimation animateIn='fadeIn' offset={0} style={{ marginBottom: '20px' }}>
              <BookieMarquee bookies={bookies} />
            </ScrollAnimation>
          </CardReach>
        </RowFourth>
      </SectionContainer>
    </>
  );
});


